/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode, createContext, useContext, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useLocalStorage } from '../../Helpers/UseLocalStorage'
import { useNavigate } from 'react-router-dom'

interface ParentComponentProps {
  children: ReactNode
}

interface Auth0User {
  name: string
  email: string
  picture: string
}

interface Auth0Context {
  isAuthenticated: boolean
  user: Auth0User | any
  isLoading: boolean
  loginWithRedirect: (options?: any) => void
  logout: (options?: any) => void
  error: any
  isLocalAuth: boolean
}

const AuthContext = createContext<Auth0Context>({
  user: null,
  isLoading: false,
  isAuthenticated: false,
  loginWithRedirect: function () {
    throw new Error('Function not implemented.')
  },
  logout: () => {
    throw new Error('Function not implemented.')
  },
  error: { message: '' },
  isLocalAuth: false,
})

export const AuthProvider: FC<ParentComponentProps> = ({ children }) => {
  const [user] = useLocalStorage('__TG', null)

  const {
    isLoading,
    error,
    loginWithRedirect,
    isAuthenticated,
    logout: logOutUser,
    getAccessTokenSilently,
    user: userData,
  } = useAuth0<Auth0Context>()

  const logout = () => {
    localStorage.removeItem('__TG')
    logOutUser({ logoutParams: { returnTo: window.location.origin } })
  }

  const navigate = useNavigate()

  const [, setUser] = useLocalStorage('__TG', null)

  const callApi = async () => {
    const token = await getAccessTokenSilently()

    if (token) {
      const loginTime = Date.now()
      localStorage.setItem('loginTime', loginTime.toString())

      setUser({ user: userData, token, loanProviderAdminRole: false })

      navigate('/', { replace: true })

      window.location.reload()
    }
  }

  useEffect(() => {
    if (isAuthenticated && !user) {
      callApi()
    }
  }, [isAuthenticated === true])

  if (isLoading) {
    return (
      <div className='mainLoader' style={{ height: '100vh', backgroundColor: 'white' }}>
        <div className='row h-100 justify-content-center'>
          <div className='col-4 col-md-2 my-auto text-center'>
            <span className='spinner-border spinner-border-sm'></span>
          </div>
        </div>
      </div>
    )
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        error,
        user,
        logout,
        loginWithRedirect,
        isAuthenticated,
        isLocalAuth: user ? true : false,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
