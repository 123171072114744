import { BreadCrumb } from 'Components'
import React, { Key } from 'react'
import { Link } from 'react-router-dom'

const TGPlusStationSubscription = () => {
  return (
    <div className='TGPlusSubscriptionDetails tgplus-stations-subscription'>
      <div className='container'>
        <div className='container mb-5'>
          <BreadCrumb
            title={'David Sumo'}
            parent={'Tradegrid Plus'}
            parentUrl={'tgplus-subscription'}
            showTitle={false}
          ></BreadCrumb>
          <h6 className='mt-3'>All David Sumo Subscriptions</h6>
          <div className='row mt-4'>
            {[1, 2, 3, 4].map((res, index: Key) => {
              return (
                <div className='col-xl-6' key={index}>
                  <div className='card border-0 mb-3'>
                    <div className='card-header bg-white py-3'>
                      <div className='row'>
                        <div className='col-3 col-md-2 subId'>TGP #12345</div>
                        <div className='col-6 col-md-4 subDate'>
                          <svg
                            width='5'
                            height='5'
                            viewBox='0 0 5 5'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='me-3'
                          >
                            <path
                              d='M2.28 4.624C1.736 4.624 1.272 4.43733 0.888 4.064C0.514667 3.68 0.328 3.216 0.328 2.672C0.328 2.11733 0.514667 1.65333 0.888 1.28C1.272 0.896 1.736 0.704 2.28 0.704C2.83467 0.704 3.304 0.896 3.688 1.28C4.072 1.65333 4.264 2.11733 4.264 2.672C4.264 3.216 4.072 3.68 3.688 4.064C3.304 4.43733 2.83467 4.624 2.28 4.624Z'
                              fill='#808080'
                            />
                          </svg>
                          24/09/2024, 16:06:35
                        </div>

                        <div className='col-4 col-md-6 text-md-end mt-2 mt-md-0'>
                          <span className='status-success '>Active</span>
                        </div>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='capacity'>10kva Solar Capacity</div>
                        </div>
                        <div className='col-md-6 text-lg-end'>
                          <div className='capacity-price'>N800,000.00/m</div>
                        </div>
                        <div className='col-md-12 station-details  mt-3'>
                          Bovas Nig Ltd | 5 Allen Ave Ikeja. Lagos.
                        </div>
                        <div className='col-md-12 next-due-date  mt-3'>Next Due Date 28/06/24</div>
                      </div>
                    </div>
                    <div className='card-footer bg-white text-center py-3'>
                      <Link to='/tgplus-subscription/TGPlusApp_01JAZNX9GRCM6JQX174T9PCKH3'>
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TGPlusStationSubscription
