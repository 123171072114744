import React, { Key, useEffect, useState } from 'react'
import './Requests.scss'
import { AdminRequest, CreateRequest, UserRequest } from '../../Components'
import { HorizontalLoader, ModalWrapper } from '../../../../Components'
import { bankTransferPaymentModel } from 'Models/orderType'
import { Link } from 'react-router-dom'
import { TimeConverter } from 'Helpers/TimeConverter'
import { CurrencyFormatter } from 'Helpers'
import Swal from 'sweetalert2'
import { Logger } from 'Helpers/Logger'
import requestService from 'Services/requestService'

const Requests = () => {
  const [btPageNumber, setBtPageNumber] = useState(1)
  const [sortOrder] = useState('DESC')
  const [btstatus] = useState('PENDING_CONFIRMATION')

  const [tab, setTab] = useState(0)

  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal((prevState) => !prevState)
  }

  const [loadbt, setLoadbt] = useState(false)

  const [btPaginationData, setBtPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const [bankTransfers, setBankTransfers] = useState<bankTransferPaymentModel[]>()

  const getBankTransfers = async () => {
    setLoadbt(true)
    await requestService.bankTransferPayment(btPageNumber, sortOrder, btstatus).then(
      (response) => {
        setLoadbt(false)
        console.log('response bankTransferPayment', response?.data.data.dataset)
        setBankTransfers(response?.data.data.dataset)

        setBtPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })
      },
      (error: { response: object }) => {
        setLoadbt(false)
        Logger('response error bankTransferPayment', error.response)
      },
    )
  }

  useEffect(() => {
    getBankTransfers()
  }, [])

  const confirmPayment = (id: string, status: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action is not reversible, Are you sure you want to continue?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#186AFF',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${status === 'CANCELLED' ? 'cancel' : 'confirm'}!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await requestService.confirmBankTransfer(id, status).then(
          () => {
            getBankTransfers()
            Swal.fire(
              'Done!',
              `Payment has been ${status === 'CANCELLED' ? 'cancelled' : 'confirmed'} succesfully`,
              'success',
            )
          },
          (error: { response: { data: { error: string } } }) => {
            console.log('error', error.response)

            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              text: error.response.data.error,
              showConfirmButton: false,
              timer: 5000,
            })
          },
        )
      }
    })
  }

  return (
    <div className='requests'>
      <div className='container mt-4'>
        <div className='container'>
          <div className='card border-0'>
            <div className='card-header border-0 px-0'>
              <div className='row'>
                <div className='col-6 col-md-6 my-auto'>
                  <div className='title'>All Quote Requests</div>
                </div>
                <div className='col-6 col-md-6 text-end'></div>
              </div>

              <div className='row mt-2'>
                <div className='col-12 col-md-8'>
                  <div className='head-tab'>
                    <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link active'
                          id='pills-home-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-home'
                          type='button'
                          role='tab'
                          aria-controls='pills-home'
                          aria-selected='true'
                          onClick={() => setTab(0)}
                        >
                          Users Requests
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link'
                          id='pills-profile-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-profile'
                          type='button'
                          role='tab'
                          aria-controls='pills-profile'
                          aria-selected='false'
                          onClick={() => setTab(1)}
                        >
                          Admin Created
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link'
                          id='pills-profile-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-profile'
                          type='button'
                          role='tab'
                          aria-controls='pills-profile'
                          aria-selected='false'
                          onClick={() => setTab(2)}
                        >
                          Bank Transfers Request
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='col-12 col-md-4 text-end'>
                  <button className='btn btn-add-company' onClick={toggleModal}>
                    Create New
                  </button>
                </div>
              </div>
            </div>
            <div className='tab-content' id='pills-tabContent'>
              <div
                className='tab-pane fade show active'
                id='pills-home'
                role='tabpanel'
                aria-labelledby='pills-home-tab'
              >
                {tab == 0 && <UserRequest></UserRequest>}
              </div>
              <div
                className='tab-pane fade'
                id='pills-profile'
                role='tabpanel'
                aria-labelledby='pills-profile-tab'
              >
                {tab == 1 && <AdminRequest></AdminRequest>}
              </div>

              {tab == 2 && (
                <div className='card border-0'>
                  <div className='card border-0'>
                    <div className='card-body'>
                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <td>ID</td>
                              <td>Customer</td>
                              {/* <td>Product</td> */}
                              <td>Amount</td>
                              <td>Status</td>
                              <td>Date</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            {bankTransfers &&
                              bankTransfers.map((data: bankTransferPaymentModel, index: Key) => (
                                <tr key={index}>
                                  <td>#{Number(index)}</td>
                                  <td>
                                    {data.customer.firstName}
                                    {data.customer.lastName}
                                  </td>
                                  {/* <td>{data.orderDetails.product.informalName}</td> */}
                                  <td>₦{CurrencyFormatter(data.transferredAmountMajor ?? 0)}</td>
                                  <td>{data.status}</td>
                                  <td>{TimeConverter(data.createdAt)}</td>
                                  <td className='dropdown option-dropdown'>
                                    <button
                                      className='btn border-0 dropdown-toggle'
                                      data-bs-toggle='dropdown'
                                      aria-expanded='false'
                                      data-bs-popper-config='{"strategy":"fixed"}'
                                    >
                                      <svg
                                        width='18'
                                        height='18'
                                        viewBox='0 0 18 18'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z'
                                          fill='#707070'
                                        />
                                        <path
                                          d='M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z'
                                          fill='#707070'
                                        />
                                        <path
                                          d='M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z'
                                          fill='#707070'
                                        />
                                      </svg>
                                    </button>

                                    <ul className='dropdown-menu dropdown-menu-end export-dropdown border-0 shadow'>
                                      <li className='dropdown-item p-0'>
                                        <Link
                                          to={`/bank-transfer-request/${data.id}`}
                                          className='w-100 px-2 py-2 d-flex'
                                        >
                                          Details
                                        </Link>
                                      </li>
                                      <li
                                        className='dropdown-item'
                                        onClick={() => confirmPayment(data.id, 'CONFIRMED')}
                                      >
                                        <Link to=''>Confirm Payment</Link>
                                      </li>

                                      {data.status === 'PENDING_CONFIRMATION' && (
                                        <li
                                          className='dropdown-item'
                                          onClick={() => confirmPayment(data.id, 'CANCELLED')}
                                        >
                                          <Link to=''>Cancel Payment</Link>
                                        </li>
                                      )}
                                    </ul>
                                  </td>
                                </tr>
                              ))}

                            {loadbt === false && !bankTransfers && (
                              <tr>
                                <td colSpan={8} className='text-center'>
                                  No bank transfer order found
                                </td>
                              </tr>
                            )}
                            {loadbt === true && !bankTransfers && (
                              <tr>
                                <td colSpan={8} className='text-center'>
                                  <HorizontalLoader></HorizontalLoader>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper showModal={showModal} closeModal={toggleModal} title='Create Request'>
        <CreateRequest toggleModal={toggleModal}></CreateRequest>
      </ModalWrapper>
    </div>
  )
}

export default Requests
