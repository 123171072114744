import { Logger } from 'Helpers/Logger'
import { TimeConverter } from 'Helpers/TimeConverter'
import { tgplusModel } from 'Models'
import tradegridPlusService from 'Services/tradegridPlusService'
import { BreadCrumb, HorizontalLoader, Pagination } from 'Components'
import SearchInput from 'Components/SearchInput/SearchInput'
import React, { Key, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './TGPlusSubscription.scss'

const TGPlusStations = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [sortOrder, setSortOrder] = useState('DESC')
  const [status, setStatus] = useState('ALL')

  const [order, setOrder] = useState<tgplusModel[]>([])
  const [loading, setLoading] = useState(false)

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const getTransaction = async () => {
    setLoading(true)

    await tradegridPlusService.index(pageNumber, sortOrder, status).then(
      (response) => {
        setLoading(false)
        setOrder(response?.data.data.dataset)
        console.log('response transactionService', response)

        setPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })
      },
      (error: { response: object }) => {
        setLoading(false)
        Logger('response error', error.response)
      },
    )
  }

  useEffect(() => {
    getTransaction()
  }, [sortOrder, pageNumber])

  function formatString(input: string) {
    const lowerCaseString = input.toLowerCase()

    // Remove underscores
    const formattedString = lowerCaseString.replace(/_/g, ' ')

    // capitalize status
    const capitalizedString = formattedString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
    return capitalizedString
  }
  return (
    <div className='TGPlusSubscription'>
      <div className='container'>
        <div className='container mb-5'>
          <BreadCrumb
            title={'David Sumo'}
            parent={'Tradegrid Plus'}
            parentUrl={'tgplus-subscription'}
            showTitle={false}
          ></BreadCrumb>
          <div className='card border-0'>
            <div className='card-header border-0'>
              <div className='row'>
                <div className='col-12 col-md-8 my-auto'>
                  <div className='title'>All Tradegrid Plus Stations</div>
                </div>
                {/* <div className='col-md-4 text-md-end'>
                <button className='btn btn-add-tgplus' onClick={toggleModal}>
                  Create New
                </button>
              </div> */}
              </div>
            </div>
          </div>
          <div className='card border-0'>
            <div className='card-header border-0 px-0'>
              <div className='row mt-2'>
                <div className='col-12 col-md-8'>
                  <SearchInput
                    onSearch={() => {
                      return
                    }}
                    placeholder={'Search User ID or Name'}
                  ></SearchInput>
                </div>
                <div className='col-6 col-md-2 dropdown'>
                  <button
                    className='btn btn-filter dropdown-toggle'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <svg
                      width='17'
                      height='17'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z'
                        fill='#868484'
                      />
                      <path
                        d='M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z'
                        fill='#868484'
                      />
                    </svg>
                    Filters
                  </button>
                  <div className='dropdown-menu dropdown-menu-end border-0 shadow filter-dropdown'>
                    <div className='dropdown-item'>
                      <form action=''>
                        <div className='form-group'>
                          <label htmlFor=''>Sort List</label>
                          <select
                            id=''
                            className='form-control'
                            onChange={(e) => {
                              setSortOrder(e.target.value)
                            }}
                          >
                            <option value='DESC'>Descending Order</option>
                            <option value='ASC'>Ascending Order</option>
                          </select>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-md-6'>
                            <button
                              type='button'
                              className='btn btn-reset'
                              onClick={() => setSortOrder('DESC')}
                            >
                              Reset
                            </button>
                          </div>
                          <div className='col-md-6 text-end'>
                            <button
                              type='button'
                              className='btn btn-filter'
                              onClick={() => setSortOrder('DESC')}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-2 dropdown'>
                  <button
                    className='btn btn-export dropdown-toggle'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <svg
                      width='17'
                      height='17'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9 11V17L11 15'
                        stroke='#868484'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9 17L7 15'
                        stroke='#868484'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14'
                        stroke='#868484'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M22 10H18C15 10 14 9 14 6V2L22 10Z'
                        stroke='#868484'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>{' '}
                    Export
                  </button>

                  <ul className='dropdown-menu dropdown-menu-end export-dropdown border-0 shadow'>
                    {/* <li className='dropdown-item' onClick={()=>exportTransactions('application/pdf')}> Export as .pdf</li>  */}
                    {/* <li className='dropdown-item' onClick={()=>exportTransactions('text/csv')}> Export as .csv</li> */}
                    {/* <li className='dropdown-item' onClick={()=>exportTransactions('text/doc')}> Export as .doc </li>  */}
                  </ul>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>User</td>
                      <td>Station Name</td>
                      <td>Number of Pumps</td>
                      <td>Capacity</td>
                      <td>Status</td>
                      <td>Date</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === true && order.length === 0 && (
                      <tr>
                        <td colSpan={8} className='text-center'>
                          <HorizontalLoader></HorizontalLoader>
                        </td>
                      </tr>
                    )}
                    {order.length > 0 &&
                      order.map(
                        (data: tgplusModel, index: Key) =>
                          Number(index) === 0 && (
                            <tr key={index}>
                              <td>{Number(index) + 1}</td>
                              <td>
                                {data.customer.firstName} {data.customer.lastName}
                              </td>
                              <td>{data.stationName}</td>
                              <td>{data.noOfPumps}</td>
                              <td>{data.solarPowerCapacity ?? data.solarPowerCapacity}</td>
                              <td>
                                <span
                                  className={
                                    ['APPROVED', 'INSTALLATION_COMPLETED', 'ACTIVE'].includes(
                                      data?.status,
                                    )
                                      ? 'status-success text-dark ms-2'
                                      : 'status-danger'
                                  }
                                >
                                  {formatString(data.status)}
                                </span>
                              </td>
                              <td>{TimeConverter(data.createdAt)}</td>
                              <td>
                                <Link to={`/tgplus-stations-subscription/${data.id}`}>
                                  <button className='btn btn-sm'>
                                    <svg
                                      width='20'
                                      height='20'
                                      viewBox='0 0 25 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M22.2188 9.14969C19.9088 5.51969 16.5287 3.42969 12.9688 3.42969C11.1888 3.42969 9.45875 3.94969 7.87875 4.91969C6.29875 5.89969 4.87875 7.32969 3.71875 9.14969C2.71875 10.7197 2.71875 13.2697 3.71875 14.8397C6.02875 18.4797 9.40875 20.5597 12.9688 20.5597C14.7487 20.5597 16.4788 20.0397 18.0588 19.0697C19.6388 18.0897 21.0588 16.6597 22.2188 14.8397C23.2188 13.2797 23.2188 10.7197 22.2188 9.14969ZM12.9688 16.0397C10.7288 16.0397 8.92875 14.2297 8.92875 11.9997C8.92875 9.76969 10.7288 7.95969 12.9688 7.95969C15.2087 7.95969 17.0088 9.76969 17.0088 11.9997C17.0088 14.2297 15.2087 16.0397 12.9688 16.0397Z'
                                        fill='#FC6FBB'
                                      />
                                      <path
                                        d='M12.9687 9.13965C11.3987 9.13965 10.1187 10.4196 10.1187 11.9996C10.1187 13.5696 11.3987 14.8496 12.9687 14.8496C14.5387 14.8496 15.8287 13.5696 15.8287 11.9996C15.8287 10.4296 14.5387 9.13965 12.9687 9.13965Z'
                                        fill='#FC6FBB'
                                      />
                                    </svg>
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          ),
                      )}

                    {loading === false && order.length === 0 && (
                      <tr>
                        <td colSpan={8} className='text-center'>
                          No transaction found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {paginationData.total >= paginationData.pageSize && (
                <div className='pagination w-100 d-flex justify-content-center d-none'>
                  {paginationData.total && (
                    <Pagination
                      className='pagination-bar ps-0'
                      currentPage={Number(paginationData.pageNumber)}
                      totalCount={paginationData.total}
                      pageSize={paginationData.pageSize}
                      onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TGPlusStations
