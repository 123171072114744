import React, { Fragment, useEffect, useState } from 'react'
import './TGPlusSubscriptionDetails.scss'
import { useParams } from 'react-router-dom'
import { Logger } from 'Helpers/Logger'
import tradegridPlusService from 'Services/tradegridPlusService'
import { tgplusModel } from 'Models'
import { TimeConverter } from 'Helpers/TimeConverter'
import { BreadCrumb, ModalWrapper } from 'Components'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import { CurrencyFormatter } from '../../../../../Helpers/CurrencyFormatter'
import { useDeliveryCountdown } from 'Hooks'

interface formProps {
  monthlySubscriptionMajor: number
  solarPowerCapacity: string
  nextSubscriptionPaymentDueDate: Date
  installationCompletedAt: Date
}

const TGPlusSubscriptionDetails = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  const [transaction, setTransaction] = useState<tgplusModel>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal((prevState) => !prevState)
  }

  const [approveRequest, setApproveRequest] = useState(false)
  const [approveSubRequest, setApproveSubRequest] = useState(false)

  const [rejectionRequest, setRejectionRequest] = useState(false)

  const [installationRequest, setInstallationRequest] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formProps>()

  const getSubscription = async () => {
    setLoading(true)
    await tradegridPlusService.single(1, subscriptionId).then(
      (response) => {
        setLoading(false)
        setTransaction(response?.data.data.dataset[0])
        console.log('response transactionService', response)
      },
      (error: { response: object }) => {
        setLoading(false)
        Logger('response error', error.response)
      },
    )
  }

  useEffect(() => {
    getSubscription()
  }, [subscriptionId])

  function formatString(input: string) {
    const lowerCaseString = input.toLowerCase()

    // Remove underscores
    const formattedString = lowerCaseString.replace(/_/g, ' ')

    // capitalize status
    const capitalizedString = formattedString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
    return capitalizedString
  }

  const transformFileUploadCategory = (fileUploadCategory: string): string => {
    if (fileUploadCategory === 'TRADEGRID_PLUS_APPLICATION_CAC_7') {
      return 'Application CAC 7'
    } else if (fileUploadCategory === 'PLP_APPLICATION_CAC_CERTIFICATE') {
      return 'CTC of CAC Registration form'
    }
    return 'Unknown file upload category'
  }

  const submit = handleSubmit(async (data: formProps) => {
    setApproveRequest(true)
    console.log('handleSubmit', data)

    await tradegridPlusService.subscriptionInstallationCompleted(data, subscriptionId).then(
      () => {
        setApproveRequest(false)
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Intallation Completed',
          showConfirmButton: false,
          timer: 2000,
        })
        getSubscription()
      },
      (error) => {
        setApproveRequest(false)
        console.log('update Subscription error', error)

        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: error.response.data.error,
          showConfirmButton: false,
          timer: 1500,
        })
      },
    )
  })

  const reject = async () => {
    setRejectionRequest(true)
    await tradegridPlusService.approveSubscription('REJECTED', subscriptionId).then(
      () => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Rejection Completed',
          showConfirmButton: false,
          timer: 2000,
        })

        setRejectionRequest(false)

        setTimeout(() => {
          getSubscription()
          toggleModal()
        }, 1000)
      },
      (error) => {
        setRejectionRequest(false)
        console.log('update Subscription error', error)

        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: error.response.data.error,
          showConfirmButton: false,
          timer: 1500,
        })
      },
    )
  }

  const ExpiryDate = (approvedDate: string) => {
    const countDown = useDeliveryCountdown(approvedDate)
    return countDown
  }

  const approveInstallation = async () => {
    setApproveSubRequest(true)
    await tradegridPlusService.approveSubscription('APPROVED', subscriptionId).then(
      async () => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Approval Completed',
          showConfirmButton: false,
          timer: 2000,
        })
        setApproveSubRequest(false)

        setTimeout(() => {
          getSubscription()
        }, 1000)
      },
      (error) => {
        setApproveSubRequest(false)
        console.log('approveSubscription error', error)

        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: error.response.data.error,
          showConfirmButton: false,
          timer: 1500,
        })
      },
    )
  }

  const [showAdditionalFields, setShowAdditionalFields] = useState(false)

  return (
    <div className='TGPlusSubscriptionDetails'>
      <div className='container'>
        <div className='container mb-5'>
          <BreadCrumb
            title={'Subscription Details'}
            parent={'Tradegrid Plus'}
            parentUrl={'tgplus-subscription'}
            showTitle={false}
          ></BreadCrumb>
          <h6 className='mt-3'>Subscription Details</h6>

          <div className='row'>
            <div className='col-md-6'>
              <div className='card mt-3 mb-5 border-0'>
                <div className='card-header bg-white'>
                  <b>TGP #12345</b>{' '}
                  <svg
                    className='mx-2'
                    width='5'
                    height='5'
                    viewBox='0 0 5 5'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M2.28 4.624C1.736 4.624 1.272 4.43733 0.888 4.064C0.514667 3.68 0.328 3.216 0.328 2.672C0.328 2.11733 0.514667 1.65333 0.888 1.28C1.272 0.896 1.736 0.704 2.28 0.704C2.83467 0.704 3.304 0.896 3.688 1.28C4.072 1.65333 4.264 2.11733 4.264 2.672C4.264 3.216 4.072 3.68 3.688 4.064C3.304 4.43733 2.83467 4.624 2.28 4.624Z'
                      fill='#808080'
                    />
                  </svg>{' '}
                  {TimeConverter(transaction?.createdAt)}
                </div>
                <div className='card-body'>
                  <div className='profile-item row'>
                    <div className='name col-6'>User</div>
                    <div className='value col-6'>
                      {transaction?.customer.firstName} {transaction?.customer.lastName}
                    </div>
                  </div>
                  <div className='profile-item row'>
                    <div className='name col-6'>Retail Station Name:</div>
                    <div className='value col-6'>{transaction?.stationName}</div>
                  </div>
                  <div className='profile-item row'>
                    <div className='name col-6'>Products:</div>
                    <div className='value col-6'>
                      {transaction?.solarPowerCapacity} Kva Solar Capacity
                    </div>
                  </div>
                  <div className='profile-item row'>
                    <div className='name col-6'>No of Pumps:</div>
                    <div className='value col-6'>{transaction?.noOfPumps}</div>
                  </div>
                  <div className='profile-item row'>
                    <div className='name col-6'>Daily Sales Volume:</div>
                    <div className='value col-6'>{transaction?.dailySalesVolume}</div>
                  </div>
                  <div className='profile-item row'>
                    <div className='name col-6'>Station Address:</div>
                    <div className='value col-6'>{transaction?.stationAddress}</div>
                  </div>
                  <div className='profile-item row'>
                    <div className='name col-6'>State:</div>
                    <div className='value col-6'>{transaction?.stationState}</div>
                  </div>
                </div>

                <div className='container ducumentation mb-4'>
                  {transaction?.verificationDocuments &&
                    transaction?.verificationDocuments.length > 0 && (
                      <div className='col-md-12'>
                        <div className='my-3 title'>Documentation</div>
                        <div className='row'>
                          {transaction?.verificationDocuments.map((res, index) => {
                            return (
                              <div className='col-6 col-md-3' key={index}>
                                <div
                                  className='uploadedDocument'
                                  style={{
                                    backgroundImage: `linear-gradient(to bottom, rgba(000, 000, 000, 0.20), rgba(000, 000, 000, 0.20)), url(${res.url})`,
                                  }}
                                >
                                  {/* <img src={res.url} className="w-100 rounded"></img> */}

                                  <div className='icons'>
                                    <a href={res.url} target='_blank' rel='noreferrer'>
                                      <button className='btn'>
                                        <svg
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'
                                        >
                                          <path
                                            d='M11.9999 16.3319C9.60992 16.3319 7.66992 14.3919 7.66992 12.0019C7.66992 9.61187 9.60992 7.67188 11.9999 7.67188C14.3899 7.67188 16.3299 9.61187 16.3299 12.0019C16.3299 14.3919 14.3899 16.3319 11.9999 16.3319ZM11.9999 9.17188C10.4399 9.17188 9.16992 10.4419 9.16992 12.0019C9.16992 13.5619 10.4399 14.8319 11.9999 14.8319C13.5599 14.8319 14.8299 13.5619 14.8299 12.0019C14.8299 10.4419 13.5599 9.17188 11.9999 9.17188Z'
                                            fill='#292D32'
                                          />
                                          <path
                                            d='M12.0001 21.0205C8.24008 21.0205 4.69008 18.8205 2.25008 15.0005C1.19008 13.3505 1.19008 10.6605 2.25008 9.00047C4.70008 5.18047 8.25008 2.98047 12.0001 2.98047C15.7501 2.98047 19.3001 5.18047 21.7401 9.00047C22.8001 10.6505 22.8001 13.3405 21.7401 15.0005C19.3001 18.8205 15.7501 21.0205 12.0001 21.0205ZM12.0001 4.48047C8.77008 4.48047 5.68008 6.42047 3.52008 9.81047C2.77008 10.9805 2.77008 13.0205 3.52008 14.1905C5.68008 17.5805 8.77008 19.5205 12.0001 19.5205C15.2301 19.5205 18.3201 17.5805 20.4801 14.1905C21.2301 13.0205 21.2301 10.9805 20.4801 9.81047C18.3201 6.42047 15.2301 4.48047 12.0001 4.48047Z'
                                            fill='#292D32'
                                          />
                                        </svg>
                                      </button>
                                    </a>
                                    <a href={res.url} target='_blank' rel='noreferrer' download>
                                      <button className='btn'>
                                        <svg
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'
                                        >
                                          <path
                                            d='M9 17.75C8.9 17.75 8.81 17.73 8.71 17.69C8.43 17.58 8.25 17.3 8.25 17V11C8.25 10.59 8.59 10.25 9 10.25C9.41 10.25 9.75 10.59 9.75 11V15.19L10.47 14.47C10.76 14.18 11.24 14.18 11.53 14.47C11.82 14.76 11.82 15.24 11.53 15.53L9.53 17.53C9.39 17.67 9.19 17.75 9 17.75Z'
                                            fill='#292D32'
                                          />
                                          <path
                                            d='M9.00043 17.7514C8.81043 17.7514 8.62043 17.6814 8.47043 17.5314L6.47043 15.5314C6.18043 15.2414 6.18043 14.7614 6.47043 14.4714C6.76043 14.1814 7.24043 14.1814 7.53043 14.4714L9.53043 16.4714C9.82043 16.7614 9.82043 17.2414 9.53043 17.5314C9.38043 17.6814 9.19043 17.7514 9.00043 17.7514Z'
                                            fill='#292D32'
                                          />
                                          <path
                                            d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z'
                                            fill='#292D32'
                                          />
                                          <path
                                            d='M22 10.7485H18C14.58 10.7485 13.25 9.41852 13.25 5.99852V1.99852C13.25 1.69852 13.43 1.41852 13.71 1.30852C13.99 1.18852 14.31 1.25852 14.53 1.46852L22.53 9.46852C22.74 9.67852 22.81 10.0085 22.69 10.2885C22.57 10.5685 22.3 10.7485 22 10.7485ZM14.75 3.80852V5.99852C14.75 8.57852 15.42 9.24852 18 9.24852H20.19L14.75 3.80852Z'
                                            fill='#292D32'
                                          />
                                        </svg>
                                      </button>
                                    </a>
                                  </div>
                                </div>
                                <div className='doc-name'>
                                  {transformFileUploadCategory(res.fileCategory)}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='card mt-3 mb-3 border-0'>
                <div className='card-header bg-white'>
                  <div className='row'>
                    <div className='col-6 fs-6 text-dark'>Solar Capacity</div>
                    <div className='col-6 text-end'>
                      {transaction && (
                        <span
                          className={
                            ['APPROVED', 'INSTALLATION_COMPLETED', 'ACTIVE'].includes(
                              transaction.status,
                            )
                              ? 'status-success text-dark ms-2'
                              : 'status-danger'
                          }
                        >
                          {formatString(transaction?.status)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  {transaction && transaction.status === 'APPROVED' && (
                    <form onSubmit={submit}>
                      <div className='completed-installation-form-info'>
                        <div className='title'>Installation in progress</div>
                        <div className='details'>
                          Complete when engineers installs solar system successfully
                        </div>
                      </div>

                      <div className='form-group'>
                        <label htmlFor='solarPowerCapacity'>Capacity</label>
                        <input
                          id='solarPowerCapacity'
                          type='number'
                          className='form-control'
                          {...register('solarPowerCapacity', {
                            required: 'this field can not be empty',
                          })}
                        />
                        {errors.solarPowerCapacity && (
                          <span className='errors'>{errors.solarPowerCapacity.message}</span>
                        )}
                      </div>

                      <div className='form-group mt-3'>
                        <label htmlFor='monthlySubscriptionMajor'>Monthly Subscription fee</label>
                        <input
                          type='number'
                          id='monthlySubscriptionMajor'
                          className='form-control'
                          {...register('monthlySubscriptionMajor', {
                            required: 'this field can not be empty',
                          })}
                        />
                        {errors.monthlySubscriptionMajor && (
                          <span className='errors'>{errors.monthlySubscriptionMajor.message}</span>
                        )}
                      </div>

                      {showAdditionalFields === true && (
                        <Fragment>
                          <div className='form-group mt-3'>
                            <label htmlFor='nextSubscriptionPaymentDueDate'>
                              Next subscription payment due date
                            </label>
                            <input
                              type='date'
                              id='nextSubscriptionPaymentDueDate'
                              className='form-control'
                              {...register('nextSubscriptionPaymentDueDate')}
                            />
                            {errors.nextSubscriptionPaymentDueDate && (
                              <span className='errors'>
                                {errors.nextSubscriptionPaymentDueDate.message}
                              </span>
                            )}
                          </div>

                          <div className='form-group mt-3'>
                            <label htmlFor='installationCompletedAt'>
                              Installation completed date
                            </label>
                            <input
                              type='date'
                              id='installationCompletedAt'
                              className='form-control'
                              {...register('installationCompletedAt')}
                            />
                            {errors.installationCompletedAt && (
                              <span className='errors'>
                                {errors.installationCompletedAt.message}
                              </span>
                            )}
                          </div>
                        </Fragment>
                      )}

                      <div className='d-flex align-items-center show-more'>
                        <div className='flex-grow-1 border-bottom'></div>
                        <span
                          className='mx-3'
                          onClick={() => setShowAdditionalFields(!showAdditionalFields)}
                        >
                          Show {showAdditionalFields === false ? 'more' : 'less'}
                          <svg
                            width='12'
                            height='12'
                            viewBox='0 0 19 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='ms-2'
                          >
                            <circle
                              cx='9'
                              cy='9'
                              r='8.28'
                              transform='matrix(-4.37114e-08 1 1 4.37114e-08 0.5 0)'
                              stroke='#FC6FBB'
                              strokeWidth='1.44'
                            />
                            {showAdditionalFields === false ? (
                              <path
                                d='M12.8417 7.69727L10.0911 10.4479C9.76621 10.7727 9.23465 10.7727 8.9098 10.4479L6.15918 7.69727'
                                stroke='#FC6FBB'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            ) : (
                              <path
                                d='M12.8417 10.3027L10.0911 7.55211C9.76621 7.22727 9.23465 7.22727 8.9098 7.55211L6.15918 10.3027'
                                stroke='#FC6FBB'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            )}
                          </svg>
                        </span>
                        <div className='flex-grow-1 border-bottom'></div>
                      </div>

                      <div className='form-group mt-3'>
                        <button type='submit' className='btn approve-btn' disabled={approveRequest}>
                          {' '}
                          {approveRequest && (
                            <span className='spinner-border spinner-border-sm'></span>
                          )}{' '}
                          Installation Completed
                        </button>
                      </div>
                    </form>
                  )}

                  {transaction && transaction.solarPowerCapacity && (
                    <div className='row h-100 capacity-details'>
                      <div className='col-md-6 text-start'>
                        <div className='capacity'>{transaction.solarPowerCapacity}kva</div>
                      </div>
                      <div className='col-md-6 my-auto text-end'>
                        <div className='amount'>
                          @N{CurrencyFormatter(transaction.monthlySubscriptionMajor)}/m
                        </div>
                        <div className='due-date'>
                          Next Due Date {TimeConverter(transaction.nextSubscriptionPaymentDueDate)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {transaction && transaction.status === 'PENDING_APPROVAL' && (
                <div className='card border-0 mb-3 approval-card'>
                  <div className='card-body'>
                    <div className='row h-100'>
                      <div className='col-md-6 my-auto'>
                        <div className='fs-4 title'>Approve Subscription</div>
                      </div>
                      <div className='col-md-6 my-auto value text-end'>
                        <button
                          className='btn btn-default'
                          onClick={approveInstallation}
                          disabled={approveSubRequest}
                        >
                          {' '}
                          {approveSubRequest && (
                            <span className='spinner-border spinner-border-sm'></span>
                          )}{' '}
                          Approve
                        </button>
                        <button type='button' className='btn reject-btn ms-3' onClick={toggleModal}>
                          Reject
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {transaction && transaction.solarPowerCapacity && (
                <div className='card border-0 mb-3 delivery-details'>
                  <div className='card-body'>
                    <div className='row h-100'>
                      <div className='col-md-9'>
                        <div className='fs-4 title'>Free Delivery</div>
                      </div>
                      <div className='col-md-3 my-auto value'>
                        <svg
                          width='15'
                          height='15'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
                            fill='#516F93'
                          />
                          <path
                            d='M15.7096 15.9298C15.5796 15.9298 15.4496 15.8998 15.3296 15.8198L12.2296 13.9698C11.4596 13.5098 10.8896 12.4998 10.8896 11.6098V7.50977C10.8896 7.09977 11.2296 6.75977 11.6396 6.75977C12.0496 6.75977 12.3896 7.09977 12.3896 7.50977V11.6098C12.3896 11.9698 12.6896 12.4998 12.9996 12.6798L16.0996 14.5298C16.4596 14.7398 16.5696 15.1998 16.3596 15.5598C16.2096 15.7998 15.9596 15.9298 15.7096 15.9298Z'
                            fill='white'
                          />
                        </svg>
                        {transaction?.approvedAt ? ExpiryDate(transaction?.approvedAt) : 0} Days
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {transaction && transaction.solarPowerCapacity && (
                <div className='card border-0 installation-progress'>
                  <div className='card-body'>
                    <div className='row h-100'>
                      <div className='col-md-8'>
                        <div className='title'>Installation in progress</div>
                        <div className='details'>
                          Complete when engineers installs solar <br />
                          system successfully
                        </div>
                      </div>
                      <div className='col-md-4 my-auto text-end'>
                        {transaction && transaction.status === 'INSTALLATION_COMPLETED' && (
                          <Fragment>
                            {transaction && (
                              <span
                                className={
                                  ['APPROVED', 'INSTALLATION_COMPLETED', 'ACTIVE'].includes(
                                    transaction.status,
                                  )
                                    ? 'status-success text-dark ms-2'
                                    : 'status-danger'
                                }
                              >
                                Completed
                              </span>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper showModal={showModal} closeModal={toggleModal} title='Reject Application'>
        <form className='rejection-form'>
          <div className='form-group'>
            <label htmlFor=''>Reason for Rejection</label>
            <textarea className='form-control' />
          </div>

          <div className='form-group mt-3'>
            <button
              type='button'
              className='btn btn-default btn-reject-001 w-100'
              onClick={reject}
              disabled={rejectionRequest}
            >
              {rejectionRequest && <span className='spinner-border spinner-border-sm'></span>}{' '}
              Submit
            </button>
          </div>
        </form>
      </ModalWrapper>
    </div>
  )
}

export default TGPlusSubscriptionDetails
