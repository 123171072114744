import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor'

AxiosInterceptor()

const orderService = {
  async index(pageNumber: number, sortOrder: string, status: string) {
    return await axios.get(
      `/orders?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}`,
    )
  },

  async getDetails(
    referenceId: string | undefined,
    loanProviderAdminRole?: boolean,
    loanProvider?: string,
  ) {
    if (loanProviderAdminRole && loanProvider) {
      return axios.get(
        `/loanProviders/${loanProvider}/customers/orders?pageNumber=1&referenceNumber=${referenceId}`,
      )
    } else {
      return axios.get(`/orders?pageNumber=1&referenceNumber=${referenceId}`)
    }
  },
  searchOrders: async (endpoint: string, pageNumber: number, sortOrder: string) => {
    const queryParams = `${endpoint}&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}`
    return await axios.get(`orders?${queryParams}`)
  },
  async getByProduct(
    pageNumber: number,
    sortOrder: string,
    status: string,
    productId: string | undefined,
  ) {
    return axios.get(
      `/orders?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}&productId=${productId}`,
    )
  },
  async cancelOrder(orderId: string) {
    return axios.put(`/orders/${orderId}/cancel`)
  },
  async orderStatus(orderId: string | undefined, orderStatus: string) {
    return axios.put(`/order/${orderId}/status`, {
      orderStatus,
    })
  },

  async reOrder(orderId: string | undefined) {
    return await axios.post(`/orders/${orderId}/pay`)
  },
}
export default orderService
